  <template>
    <div class="modal" v-if="showModal">
      <div class="modal-content">
        <div class="modal-header">
            <div class="g-gont">
                <slot name="header">
                Add Account
                </slot>
            </div>
            <span class="close" @click="closeModal">x</span>
        </div>  
        <div class="modal-body">
            <slot name="body">
            <div class="form-group">
                <label class="g-font gl-color" for="email"> Username</label>
                <input class="form-control g-font" placeholder="Enter username" id="email" type="text" v-model="newAccount.username">
            </div>  
            <div class="row">
                <div class="col-sm-6">
                <div class="form-group">
                    <label class="g-font gl-color" for="password"> Password</label>
                    <input class="form-control g-font" placeholder="Enter Password" id="password" type="password" v-model="newAccount.password">
                </div>
                </div>
            </div>
            </slot>
        </div>
        <div class="modal-footer">
            <div v-if="errorMsg" class="error-msg on-center">{{ errorMsg }}</div>
            <slot name="footer">
              <div class="footer-btns">
                <button :disabled="formSubmitted" class="buton modal-default-button g-font" @click="addAccount">
                    ADD
                </button>
                <button class="buton g-font" @click="closeModal">
                    CANCEL
                </button>
              </div>
            </slot>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import useVuelidate from '@vuelidate/core'
  import { required } from '@vuelidate/validators'
  import { DISTRIBUTOR_CREATE_ACCOUNT
  } from "@/utils/api-routes";
  import { post } from "@/services/http";

  export default {
    name: "ModalComp",
    setup () {
        return { v$: useVuelidate() }
    },
    props: {
      showModal: Boolean,
    },
    data() {
      return {
        newAccount: {
          username: "",
          password: "",
        },
        formSubmitted: false,
        errorMsg:'',
      }
    },
    methods: {
      closeModal() {
        console.log('showModal: ', this.showModal);
        this.$emit('isShowModal', false);
      },
      async addAccount() {
        this.formSubmitted = true;
        const payload = {
            username: this.newAccount.username,
            password: this.newAccount.password,
        }
        try {
        const acc = await post(DISTRIBUTOR_CREATE_ACCOUNT, payload);
        console.log('acc ', acc);
        this.newAccount = {
            username : '',
            password : ''
        }
        if (acc === 'ok') {
            this.$emit('accountAdded', true);
        }
        } catch (error){
            const {
          response: { data },
        } = error;
          this.errorMsg = data.detail ? data.detail : "Something went wrong";
          console.log('errorMsg: ', this.errorMsg);        }

      }
    },
    validations() {
    return {
      newAccount: {
        email: { required },
        password: { required },
      },
    }
  },
  };
  </script>
  
  <style scoped>
  /* Add your modal styles here or in a separate style file */
  .modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #242460;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  height: 43%;
  width: 33%;
}

.close {
  cursor: pointer;
}
.modal-header {
    display: flex;
    justify-content: space-between;
    font-weight: bolder;
    height: 5vh;
}

.modal-body {
  height: 23vh;
  margin: 20px 0 10px 0;
}

.modal-default-button {
    margin-right: 0.3vw;
}

.form-group {
  margin-bottom: 10px;
}

.dark-placeholder::placeholder {
  color: #242460; /* Dark mode placeholder color */
}

.form-control {
    border: 1px solid #353580;
    background: transparent;
    border-radius: 4px;
    color: white;
}

label {
  display: block;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.error-msg {
  color: red;
  font-size: 0.9em;
  margin-bottom: 5px;
}

.on-center {
  display: flex;
  justify-content: center;
}
.gl-color {
  color: #1c7cc9;
  font-weight: 600;
}
.dark-gl-color {
  color: #242460;
  font-weight: 600;
}

.txt-cl {
  text-decoration: none;
  color: #242460;
  font-weight: bold;
}
.is-dark {
  text-decoration: none;
  font-weight: bold;
  color: #1c7cc9;
}

.g-font {
    font-family: "Lato", sans-serif;
 }
.form-title {
  text-align: center;
  font-size: 1.5em;
  margin-bottom: 0px;
}
.footer-btns {
    display: flex;
    justify-content: center;
}
.buton {
  color: white;
  padding: 5px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  background-color: #1c7cc9;
  opacity: 0.8;
  font-weight: bold;
  width: 5vw;
}
.buton:disabled {
  pointer-events: none;
}
@media (max-width: 568px) {

  .modal-content {
    width: 55%;
  }
  .buton {
    width: 19vw;
  }
  .modal-default-button {
    margin-right: 5px;
  }
}

</style>
  