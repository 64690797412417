<template>
  <div class="p">
    <div class="video-container">
      <iframe
        src="https://www.youtube.com/embed/MWfK0FViQUU"
        title="Autom 8 | Promo Video"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
    <div class="content">
      <h4>Autom8 works 24/7, so YOU don't have to!</h4>
      <div class="inner-content">
        <div class="left">
          <span> <img src="@/assets/images/check.png" /> Fast and Secure!</span>
          <span> <img src="@/assets/images/check.png" />24/7 Tech Support</span>
          <span>
            <img src="@/assets/images/check.png" />Utmost respect for
            privacy!</span
          >
        </div>
      </div>
    </div>
    <div class="p-sponsor">
      <h4>Optimized For Sponsors!</h4>
      <div class="sponsor">
        <div class="img">
          <img src="@/assets/images/dragobworld.png" />
        </div>
        <div class="img">
          <img src="@/assets/images/dragon_crown.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BaseUrl } from "@/utils/api-routes";

export default {
  name: "UserLogin",
  mounted() {
    this.emitter.on("open-google-popup", this.openGooglePopup);
  },
  data() {
    return { isLoading: false };
  },
  methods: {
    openGooglePopup() {
      const url = BaseUrl + "/user/login";
      window.location.href = url;
    },
  },
};
</script>

<style scoped lang="scss">
.p {
  height: calc(100vh - 250px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  overflow: auto;
  scrollbar-width: thin; /* For Firefox */
  -ms-overflow-style: none;
}
.p::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.p-sponsor {
  margin: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.sponsor {
  width: 100%;
  max-width: 600px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .img {
    background: #ffffff59;
    padding: 20px;
    border-radius: 50%;
    height: 130px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    img {
      width: 80%;
    }
  }
}

.video-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.content {
  width: 100%;
  max-width: 768px;
  display: flex;
  flex-direction: column;
  .inner-content {
    display: flex;
  }
  .left {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    flex-wrap: wrap;
    span {
      display: flex;
      align-items: center;
      margin: 5px 15px;
      img {
        height: 24px;
        width: 24px;
        margin: 0px 05px 0px 0px;
      }
    }
  }
}

@media (min-width: 320px) {
  .video-container {
    padding: 30px 0px 0px 0px;
    iframe {
      width: 768px;
      height: 250px;
    }
  }
  .content {
    h4 {
      text-align: center;
    }

    .right {
      button {
        font-size: 16px;
        width: 70px;
        img {
          width: 30px;
        }
      }
    }
  }
}

@media (min-width: 480px) {
  .video-container {
    padding: 20px 0px 0px 0px;
    iframe {
      width: 768px;
      height: 280px;
    }
  }
  .content {
    h4 {
      text-align: center;
    }

    .right {
      button {
        font-size: 16px;
        width: 70px;
        img {
          width: 30px;
        }
      }
    }
  }
}
@media (min-width: 768px) {
  .video-container {
    margin-top: 0px 0px 10px 0px;
    iframe {
      width: 768px;
      height: 290px;
    }
  }
  .content {
    h4 {
      text-align: center;
    }

    .right {
      display: flex;
      justify-content: center;
    }
  }
}
@media (min-width: 1024px) {
  .video-container {
    margin-top: 20px;
    iframe {
      width: 768px;
      height: 300px;
    }
  }
  .content {
    h4 {
      margin-bottom: 5px;
    }
  }
}

@media (min-width: 1400px) {
  .video-container {
    margin: 50px 0px 20px 0px;
    iframe {
      width: 768px;
      height: 360px;
    }
  }
}
iframe {
  border-radius: 8px;
}

@media (max-width: 568px) {
  .p {
    height: unset;
  }
  .content {
    margin-top: 25px;
  }
  .sponsor {
    flex-direction: column;
    .img {
      height: 80px;
      height: 100px;
      padding: 10px;
    }
  }
}
</style>
