const MODE_KEY = "appMode";
const USER_KEY = "appUser";

// Function to set the mode in localStorage
export const setMode = (mode) => {
  localStorage.setItem(MODE_KEY, mode);
};

// Function to get the mode from localStorage
export const getMode = () => {
  return localStorage.getItem(MODE_KEY) || "dark-mode";
};

// Function to set the user in localStorage
export const setUser = (user) => {
  localStorage.setItem(USER_KEY, user);
};

// Function to get the user from localStorage
export const getUser = () => {
  return localStorage.getItem(USER_KEY) || "";
};

export const setToken = (token) => {
  localStorage.removeItem("distributorToken");
  localStorage.setItem("distributorToken", token);
};

export const getToken = () => {
  return localStorage.getItem("distributorToken");
};

export const removeToken = () => {
  return localStorage.removeItem("distributorToken");
};

export const removeUserObject = () => {
  return localStorage.removeItem("user");
};
export const removeAccounts = () => {
  return localStorage.removeItem("accounts");
};

export const setDistributorObject = (distributor) => {
  let { full_name, email } = distributor;
  const distributorData = { full_name, email };
  localStorage.removeItem("distributor");
  localStorage.setItem("distributor", JSON.stringify(distributorData));
};

export const setAccountObj = (user) => {
  localStorage.removeItem("user");
  localStorage.setItem("user", JSON.stringify(user));
};

export const getUserObject = () => {
  const user = localStorage.getItem("user");
  if (user && user !== "undefined") {
    return JSON.parse(user);
  } else {
    return null;
  }
};

export const setAccounts = (accounts) => {
  localStorage.removeItem("accounts");
  localStorage.setItem("accounts", JSON.stringify(accounts));
};

export const getAccounts = () => {
  const accounts = localStorage.getItem("accounts");
  if (accounts && accounts.length) {
    return JSON.parse(accounts);
  } else {
    return [];
  }
};

export const setAccountObject = (account) => {
  let { username, password, unique_id } = account;
  const accountData = { username, password, unique_id };
  localStorage.removeItem("account");
  localStorage.setItem("account", JSON.stringify(accountData));
};

export const getAccountObject = () => {
  const account = localStorage.getItem("account");
  if (account && account !== "undefined") {
    return JSON.parse(account);
  } else {
    return null;
  }
};

export const removeAccountObject = () => {
  return localStorage.removeItem("account");
};

