<template>
    <div v-if="isLoading && formSubmitted" class="loader-c">
        <div class="loader"></div>
        <span>Signing Up...</span>
    </div>
    <form @submit.prevent="submitForm" class="signup-form">

       <!-- Full Name Row -->
       <div class="form-group">
        <label class="g-font gl-color" :class="{'dark-gl-color': !darkMode}" for="name"> Full Name</label>
        <input class="form-control g-font" :class="{'dark-placeholder': !darkMode}" placeholder="Enter full name" id="name" type="text" v-model="v$.form.name.$model">
        <!-- Error Message -->
          <div class="input-errors" v-for="(error, index) of v$.form.name.$errors" :key="index">
            <div class="error-msg g-font">{{ error.$message }}</div>
          </div>
      </div>  
      <!-- User Name Row -->
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label class="g-font gl-color" :class="{'dark-gl-color': !darkMode}" for="username"> Username</label>
            <input class="form-control g-font" :class="{'dark-placeholder': !darkMode}" placeholder="Enter username" id="username" type="text" v-model="v$.form.username.$model">
            <!-- Error Message -->
            <div class="input-errors" v-for="(error, index) of v$.form.username.$errors" :key="index">
              <div class="error-msg g-font">{{ error.$message }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- Email Row -->
      <div class="form-group">
        <label class="g-font gl-color" :class="{'dark-gl-color': !darkMode}" for="email"> Email address</label>
        <input class="form-control g-font" :class="{'dark-placeholder': !darkMode}" placeholder="Enter email" id="email" type="email" v-model="v$.form.email.$model">
        <!-- Error Message -->
          <div class="input-errors" v-for="(error, index) of v$.form.email.$errors" :key="index">
            <div class="error-msg g-font">{{ error.$message }}</div>
          </div>
      </div>

      <div class="form-group">
        <label class="g-font gl-color" :class="{'dark-gl-color': !darkMode}" for="phone">Phone Number</label>
        <input class="form-control g-font" :class="{'dark-placeholder': !darkMode}" placeholder="Enter phone" id="phone"  type="tel" v-model="v$.form.phone.$model" required />
        <div class="input-errors" v-for="(error, index) of v$.form.phone.$errors" :key="index">
            <div class="error-msg g-font">{{ error.$message }}</div>
        </div>
     </div>
  
      <!-- Password and Confirm Password Row -->
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label class="g-font gl-color" :class="{'dark-gl-color': !darkMode}" for="password"> Password</label>
            <input class="form-control g-font" :class="{'dark-placeholder': !darkMode}" placeholder="Enter Password" id="password" type="password" v-model="v$.form.password.$model">
            <!-- Error Message -->
            <div class="input-errors" v-for="(error, index) of v$.form.password.$errors" :key="index">
              <div class="error-msg g-font">{{ error.$message }}</div>
            </div>
          </div>
        </div>
      </div>
  
      <!-- Submit Button -->
      <div v-if="errorMsg" class="error-msg on-center">{{ errorMsg }}</div>
      <button type="submit" :disabled="formSubmitted || v$.form.$invalid" class="submit-button g-font">Signup</button>
      
    </form>    
  </template>
  
<script>
import { getMode } from "@/services/localStorage";
import useVuelidate from '@vuelidate/core'
import { required, email, minLength } from '@vuelidate/validators'
import { post } from "@/services/http";
import {
  SIGNUP_DISTRIBUTOR,
} from "@/utils/api-routes";
import { setDistributorObject } from "@/services/localStorage";
const checkIfDark = (name) => name === "dark-mode";


export function validName(name) {
  let validNamePattern = new RegExp("^[a-zA-Z0-9]+(?:[-'\\s][a-zA-Z0-9]+)*$");
  if (validNamePattern.test(name)){
    return true;
  }
  return false;
}

export function validPhoneNumber(num) {
  let validNamePattern = new RegExp(/^\d{8,15}$/);
  if (validNamePattern.test(num)){
    return true;
  }
  return false;
}


export default {
  name: 'SignUp',
  setup () {
    return { v$: useVuelidate() }
  },

  data() {
    return {
      darkMode: checkIfDark(getMode()),
      form: {
        name: '',
        username: '',
        email: '',
        password: '',
        phone: '',
      },
      errorMsg: "",
      formSubmitted: false,
      isLoading : true,
    }
  },

  created() {
    this.emitter.on("mode-change", (data) => {
      this.darkMode = checkIfDark(data.mode);
    });
  },

  validations() {
    return {
      form: {
        name: {required},
        username: { 
          required, name_validation: {
            $validator: validName,
            $message: 'Invalid UserName. Valid name only contain letters and numbers'
          } 
        },
        email: { required, email },
        password: { required, min: minLength(5) },
        phone: { required, phone_validation: {
            $validator: validPhoneNumber,
            $message: 'Phone number length should between 8-15'
          } }, // Assumes a 8-15-digit phone number
      },
    }
  },
  methods: {
    async submitForm() {
        try {
        console.log('Form submitted:', this.form.name, this.form.username, this.form.email, this.form.phone, this.form.password);
        const response = await post(SIGNUP_DISTRIBUTOR, {
            name: this.form.name,
            username: this.form.username,
            email: this.form.email,
            password: this.form.password,
            phone_number: this.form.phone,
        });
        console.log('token: ', response);
        if (response.access_token) {
            this.formSubmitted = true;
            setDistributorObject(response);
            this.$router.push('/notAuthorized');
        }
        } catch (error) {
            const {
          response: { data },
        } = error;
          this.errorMsg = data.detail ? data.detail : "Something went wrong";
          console.log('errorMsg: ', this.errorMsg);
          console.error('Error registering user:', error);
        }
    },
  },
}

</script>
  
<style scoped>

.loader-c {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -15vh;
}
.g-font {
    font-family: "Lato", sans-serif;
 }
.form-title {
  text-align: center;
  font-size: 1.5em;
  margin-bottom: 0px;
}

.signup-form {
  max-width: 400px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 10px;
}

.form-control {
    border: 1px solid #353580;
    background: transparent;
    border-radius: 4px;
    color: white;
}

.dark-placeholder::placeholder {
  color: #242460; /* Dark mode placeholder color */
}

label {
  display: block;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.error-msg {
  color: red;
  font-size: 0.9em;
  margin-top: 5px;
}

.on-center {
  display: flex;
  justify-content: center;
}

.submit-button {
  /* background-color: rgb(0, 172, 193, 0.11); */
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  width: 100%;
  font-size: 1em;
  border-radius: 4px;
  background-color: #242460;

}

.submit-button:disabled {
    /* background-color: rgb(0, 172, 193, 0.11); */
    pointer-events: none;
    background-color: #242460;

}
.submit-button:hover {
    background-color: rgba(255, 255, 255, 0.11);
}
.gl-color {
  color: #1c7cc9;
  font-weight: 600;
}
.dark-gl-color {
  color: #242460;
  font-weight: 600;
}
@media (max-width: 568px) {
  .signup-form {
    margin-top: 30px;
  }
}
</style>
  