<template>
    <div class="add-account">
      <div class="inner-add-account">
        <button @click="isShowModal">Add Account</button>
        <ModalComp 
          @isShowModal="updateShowModal"
          @accountAdded="isAccountAdded"
          :showModal="showModal"
          :addAccount="newAccount">
        </ModalComp>
      </div>
    </div>
    <div class="page" v-if="!showModal">
        <div class="inner-box" :class="{'is-agent': isAgentData}">
          <DynamicTable
            @rowDeleted="rowDeleted"
            :showSelected="true"
            :showFooter="false"
            :isLoading="loading"
            :columns="tableColumns"
            :data="tableData"
            :showArrow = "true"
            :showDelete = "true"
            :account = "account"
            :isAccount= "true"
          />
        </div>
    </div>
  </template>
  
  <script>
  import DynamicTable from "@/components/Table.vue";
  import { DELETE_ACCOUNT, GET_ACCOUNTS
  } from "@/utils/api-routes";
  import { del, get } from "@/services/http";
  import { getAccounts, setAccounts } from "@/services/localStorage";
  import ModalComp from "@/components/Modal.vue";

  export default {
    name: "accountList",
    components: {
      DynamicTable,
      ModalComp,
    },
    data() {
      return {
        tableColumns: [
          { key: "username", label: "Username" },
          { key: "password", label: "Password" },
        ],
        tableData: [],
        loading: false,
        account: {
          username: "",
          password: "",
        },
        newAccount: {
          username: "",
          password: "",
        },
        errorMsg: "",
        isAgentData: false,
        accountsData: [],
        unique_id: "",
        showModal: false,
      };
    },
    created() {
      this.getAccountsData();
    },
    methods: {
      async getAccountsData() {
        try {
          this.loading = true;
          const accounts = await get(GET_ACCOUNTS);
          console.log('accounts: ', accounts);
          setAccounts(accounts.accounts);
          this.tableData = accounts.accounts;
          this.loading = false;
        } catch(error) {
          const {
          response: { data },
        } = error;
          this.errorMsg = data.detail ? data.detail : "Something went wrong";
          console.log('errorMsg: ', this.errorMsg);
        }
      },
      async rowDeleted(data) {
        try {
          const { unique_id } = data;
          console.log(data);
          this.loading = true;
          const response = await del(DELETE_ACCOUNT + `?unique_id=${unique_id}`);
          console.log('res: ', response);
          if (response === 'ok') {
            this.loading = false;
            this.updateAccountsData(unique_id);
          }
        } catch (error) {
          console.log("Error deleting platform:", error);
        }
      },

      updateAccountsData(id) {
        const accounts = getAccounts();
        this.tableData = accounts.filter(acc => acc.unique_id !== id);
        setAccounts(this.tableData);
      },

      isShowModal() {
        this.showModal = true;
      },
      async isAccountAdded(value) {
        this.showModal = false;
        if (value) {
          try {
            this.getAccountsData();
          } catch(error) {
            const {
          response: { data },
        } = error;
          this.errorMsg = data.detail ? data.detail : "Something went wrong";
          }
        }
      },
      updateShowModal(val) {
        this.showModal = val;
      }
    },
  };
  </script>
  <style scoped lang="scss">

  .add-account {
    display: flex;
    width: 100%;

    .inner-add-account {
      width: 68.7%;
      display: flex;
      justify-content: flex-end;
      button {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 4px;
      background-color: #242460;
      border: none;
      color: #ffff;
      padding: 0.8vh;
      }
    }
  }

  .page {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 5px 0px 20px 0px;

    .inner-box {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .is-agent {
      width: 100%;
    }
  
    .foot {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      .errorMsg {
        font-size: 22px;
        color: #ff8a96;
        letter-spacing: 0.5px;
        font-weight: 600;
      }
    }
  
    .unit {
      display: flex;
      align-items: center;
      margin: 30px 0px;
  
      label {
        font-size: 25px;
        font-weight: 500;
        width: 200px;
      }
    }
  
    .select {
      padding: 10px;
      border: none;
      width: 300px;
      border-radius: 3px;
      color: #000000b0;
  
      &::placeholder {
        color: var(--input-placeholder);
        opacity: 0.3;
      }
  
      &:focus {
        outline: none;
        border-color: white;
      }
    }
  }
  
  @media (max-width: 768px) {
    .add-account {
      .inner-add-account {
      width: 100%;
    }
    }
    
    .page {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 100px;
      .inner-box {
        height: 100%;
        width: 100%;
        display: block;
      }
    }
    .unit {
      label {
        font-size: 15px !important;
      }
    }
  }
  </style>  