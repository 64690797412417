<template>
    <div class="page">
        <div class="inner-box" :class="{'is-agent': isAgentData}">
          <DynamicTable
            :showSelected="true"
            :showFooter="false"
            :isLoading="loading"
            :columns="tableColumns"
            :data="tableData"
            :showArrow = "true"
            :account = "account"
            :isDistributor = "true"
          />
        </div>
    </div>
  </template>
  
  <script>
  import DynamicTable from "@/components/Table.vue";
  import { get} from "@/services/http";
  import {
    GET_DISTRIBUTORS,
  } from "@/utils/api-routes";

  import { getDistributors, setDistributors } from "@/services/localStorage";

  
  export default {
    name: "distributorList",
    components: {
      DynamicTable,
    },
    data() {
      return {
        tableColumns: [
          { key: "email", label: "Email" },
          { key: "username", label: "Username" },
          { key: "status", label: "Status" },
        ],
        tableData: [],
        loading: false,
        distributors: {
          email: "",
          username: "",
          status: "",
        },
        errorMsg: "",
        isAgentData: false,
        distributorsData: [],
        unique_id: "",
      };
    },
    created() {
      this.fetchData();
    },
    methods: {
      async fetchData() {
        this.loading = true;          
        try {
          const distributorsList = getDistributors();
          if (distributorsList !== null) {
            this.tableData = distributorsList;
            this.loading = false;
          } else {
            const response = await get(GET_DISTRIBUTORS);
            console.log('Data: ', response.distributors);
            const data = response.distributors;
            setDistributors(data);
            this.tableData = data;
            this.distributorsData = this.tableData;
            this.loading = false;
          }
        } catch (error) {
          console.error("Error fetching accounts:", error);
        }
      },
    },
  };
  </script>
  <style scoped lang="scss">
  .page {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 20px 0px 20px 0px;

    .inner-box {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .is-agent {
      width: 100%;
    }
  
    .foot {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      .errorMsg {
        font-size: 22px;
        color: #ff8a96;
        letter-spacing: 0.5px;
        font-weight: 600;
      }
    }
  
    .unit {
      display: flex;
      align-items: center;
      margin: 30px 0px;
  
      label {
        font-size: 25px;
        font-weight: 500;
        width: 200px;
      }
    }
  
    .select {
      padding: 10px;
      border: none;
      width: 300px;
      border-radius: 3px;
      color: #000000b0;
  
      &::placeholder {
        color: var(--input-placeholder);
        opacity: 0.3;
      }
  
      &:focus {
        outline: none;
        border-color: white;
      }
    }
  }
  
  @media (max-width: 768px) {
    .page {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 100px;
      .inner-box {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
    .unit {
      label {
        font-size: 15px !important;
      }
    }
  }
  </style>  