<template>
  <div class="main">
    <div v-if="!isLoading && primaryUser?.unique_id" class="list">
      <div class="primary widget">
        <h3><b>@{{currentUser.username}}</b></h3>
        <h3>
          <img
            height="25"
            style="margin-right: 10px; margin-top: 5px;"
            src="@/assets/email.svg"
          />
          {{ this.primaryUser.email }}
          <!-- <img
            height="20"
            :src="this.primaryUser.authorised ? authorized : unauthorized"
          />
          <img
            class="home"
            @click="navigateToRoute(this.primaryUser?.unique_id)"
            src="@/assets/arrow-up-white.svg"
            height="20"
            alt=""
          /> -->
        </h3>
      </div>
      <div style="margin: 10px 0 20px 0">
        <!-- <label style="margin-left: 20px" for="">Primary</label> -->
        <label style="margin-left: 17px" for="">Status</label>
      </div>
      <div
        class="single-user widget"
        v-for="item in this.users"
        :key="item.key"
      >
        <!-- <div class="check" title="Make me primary">
          <input
            type="checkbox"
            class="status"
            :disabled="!item.authorised"
            @change="setPrimary(item.unique_id)"
          />
        </div> -->
        <div class="check" :title="item.status ? 'Disable me!' : 'Enable me!'">
          <input
            class="status"
            type="checkbox"
            style="margin-left: -10px"
            :checked="item.status"
            @change="setStatus(item.unique_id, item.status)"
          />
        </div>
        <div class="icons">
        <img
            height="30"
            @click.prevent="deleteUser(item.unique_id)"
            title="Delete"
            src="@/assets/delete.svg"
            alt=""
          />
          </div>
        <div class="email">
          <h3 :title="item.authorised ? 'Verified' : 'Unverified'">
            <span>{{ item.email }}</span>
            <img :src="item.authorised ? authorized : unauthorized" />
          </h3>
        </div>
        <div class="icons">
          <!-- <img
            height="30"
            @click.prevent="deleteUser(item.unique_id)"
            title="Delete"
            src="@/assets/delete.svg"
            alt=""
          /> -->
          <img
            height="20"
            class="arrow"
            @click.prevent="navigateToRoute(item?.unique_id)"
            title="Home"
            src="@/assets/arrow-up-white.svg"
            alt=""
          />
        </div>
      </div>
      <div class="add-new">
        <input placeholder="google@gmail.com" type="text" v-model="email" />
        <img @click="addEmail()" src="@/assets/icon-plus.svg" alt="" />
      </div>
    </div>
    <div v-else>
      <div class="loader-c">
        <div class="loader"></div>
      </div>
    </div>
    <p class="error" v-if="errorMsg">{{ errorMsg }}</p>
  </div>
</template>

<script>
import { get } from "@/services/http";
import { setToken, setUserObject, getUserObject } from "@/services/localStorage";
import {
  BaseUrl,
  GET_USER,
  SECONDARY_LOGIN,
  SET_PRIMARY,
  STATUS,
  DELETE_USER,
  EMAIL_VERIFICATION,
} from "@/utils/api-routes";

export default {
  name: "UserHome",
  data() {
    return {
      authorized: require("@/assets/shield-fill-check.svg"),
      unauthorized: require("@/assets/shield-fill-x.svg"),
      primaryUser: {},
      users: [],
      email: "",
      errorMsg: "",
      isLoading: false,
      currentUser: {},
    };
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true;
        const {
          authorised,
          email,
          status,
          unique_id,
          users,
        } = await get(GET_USER);
        this.primaryUser = {
          email,
          authorised,
          unique_id,
          status,
        };
        this.users = users?.map(
          ({
            user: {
              authorised,
              email,
              status,
              unique_id,
            },
          }) => {
            return { authorised, email, status, unique_id };
          }
        );
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching emails:", error);
      }
    },
    async addEmail() {
      this.errorMsg = "";
      const gmailRegex = /^[a-zA-Z0-9._-]+@gmail\.com$/;
      if (!gmailRegex.test(this.email)) {
        this.errorMsg = "Enter a valid Gmail address!";
        return;
      }
      try {
        await get(EMAIL_VERIFICATION + `?email=${this.email}`);
        const url =
          BaseUrl +
          SECONDARY_LOGIN +
          `?email=${this.email}&account_unique_id=${this.currentUser.unique_id}`;
        window.location.href = url;
      } catch (error) {
        const {
          response: { data },
        } = error;
        this.errorMsg = data.detail ? data.detail : "Something went wrong";
      }
    },
    async setPrimary(key) {
      this.isLoading = true;
      const data = await get(SET_PRIMARY + `?unique_id=${key}`);
      await setToken(data.access_token);
      await setUserObject(data);
      this.fetchData();
    },
    async setStatus(key, status) {
      this.isLoading = true;
      await get(
        STATUS + `?unique_id=${key}&status=${status ? "false" : "true"}`
      );
      this.fetchData();
    },
    async deleteUser(key) {
      this.isLoading = true;
      await get(DELETE_USER + `?unique_id=${key}`);
      this.fetchData();
    },
    navigateToRoute(key) {
      this.$router.push({ path: "/list/" + key });
    },
    getCurrentUser() {
      this.currentUser = getUserObject();
    }

  },
  created() {
    // this.fetchData();
    // this.getCurrentUser();
  },
};
</script>

<style scoped lang="scss">
.home {
  cursor: pointer;
  margin-left: 10px;
  transform: rotate(90deg);
}

.widget {
  background-color: #ffffff2e;
  box-shadow: 0px 0px 6px white;
  border-radius: 7px;
  margin-bottom: 10px;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  margin-top: 50px;
  .error {
    color: #e7a3a3;
    font-size: 18px;
  }
  .primary {
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #ffffff4a;
    h3 {
      display: flex;
      align-items: center;
    }
    h3,
    h4 {
      margin: 5px 0;
    }
  }
  .single-user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: auto;
    padding: 5px;
    &:hover {
      background-color: rgba(255, 255, 255, 0.237);
      cursor: pointer;
    }
    .status {
      transform: scale(0.4);
    }
    h3 {
      // min-width: 300px;
      color: white;
    }
    .email {
      margin-right: 20px;
      h3 {
        display: flex;
        align-items: center;
        img {
          height: 20px;
          margin: 2px;
        }
      }
    }
  }
  .icons {
    display: flex;
    align-items: center;
    margin-right: 10px;
    img {
      margin: 0 5px;
    }
    .arrow {
      transform: rotate(90deg);
    }
  }
  .check {
    margin-top: 4px;
    width: 50px;
  }
  .add-new {
    display: flex;
    input {
      background: transparent;
      padding: 10px 12px;
      margin: 10px;
      flex-grow: 1;
      border: none;
      border-bottom: 1px solid #ffffff4a;
      color: white;
      font-size: 20px;
      &:focus {
        outline: none;
      }
      &::placeholder {
        font-size: 18;
        color: #ffffff4a;
      }
    }
    img {
      height: 30px;
      margin: 20px;
      cursor: pointer;
    }
  }
}
.list {
  border-radius: 6px;
  box-shadow: inset 0px 0px 6px white;
  padding: 50px;
}

.status[type="checkbox"] {
  position: relative;
  width: 80px;
  height: 40px;
  -webkit-appearance: none;
  appearance: none;
  background: #b2b1b1;
  outline: none;
  border-radius: 2rem;
  cursor: pointer;
  box-shadow: inset 0 0 5px rgb(0 0 0 / 50%);
}

.status[type="checkbox"]::before {
  content: "";
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.5s;
}

.status[type="checkbox"]:checked::before {
  transform: translateX(100%);
  background: #fff;
}

.status[type="checkbox"]:checked {
  background: #25274d;
}

@media (max-width: 768px) {
  .list {
    padding: 0;
    box-shadow: none;
  }
  .main {
    .check {
      width: 40px;
    }
  }
  .email {
    margin-right: 0px !important;
    span {
      width: 150px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
</style>
