<template>
    <div class="page">
        <div class="inner-box" :class="{'is-agent': isAgentData}">
          <DynamicTable
            @filterByDates = "filterByDates"
            @clearFilter = "clearFilter"
            :showSelected="true"
            :showFooter="false"
            :isLoading="loading"
            :columns="tableColumns"
            :data="tableData"
            :showArrow = "false"
            :isAgentData = "isAgentData"
            :account = "account"
          />
        </div>
    </div>
  </template>
  
  <script>
  import DynamicTable from "@/components/Table.vue";
  import { get } from "@/services/http";
  import {
    GET_DISTRIBUTOR_ACCOUNT_STATS
  } from "@/utils/api-routes";
  import { getAccountObject } from "@/services/localStorage";

  
  export default {
    name: "accountDetails",
    components: {
      DynamicTable,
    },
    data() {
      return {
        tableColumns : [
          { key: 'email', label: "Agent"},
          { key: "Taichi", label: "Taichi"},
          { key: "VBlink", label: "VBlink"},
          { key: "Firekirin", label: "Firekirin"},
          { key: "Acebook", label: "Acebook"},
          { key: "Orionstar", label: "Orionstar"},
          { key: "GameVault999", label: "GameVault999"},
          { key: "Juwa", label: "Juwa"},
          { key: "Milkyway", label: "Milkyway"},
          { key: "BlueDragon", label: "BlueDragon"},
          { key: "GoldenDragon", label: "GoldenDragon"},
          { key: "DragonCrown", label: "DragonCrown"},
          { key: "BigWinner", label: "BigWinner"},
          { key: "UltraPanda", label: "UltraPanda"},
          { key: "DragonWorld", label: "DragonWorld"},
          { key: "PandaMaster", label: "PandaMaster"},
          { key: "CandyLand", label: "CandyLand"},
          { key: "RiverSweep", label: "RiverSweep"},
          { key: "Yolo", label: "Yolo"},
          { key: "total", label: "Total"},
        ],
        tableData: [
        ],
        loading: false,
        account: {},
        errorMsg: "",
        isAgentData: false,
        accountsData: [],
      };
    },
    created() {
      this.fetchAccountDetails();
    },
    methods: {
      async fetchAccountDetails() {
        this.account = getAccountObject();
        this.loading = true;
        console.log('this.account: ', this.account);
        this.isAgentData = true;
        try {
          const usersData = await get(GET_DISTRIBUTOR_ACCOUNT_STATS + `?account_unique_key=${this.account.unique_id}`);
          console.log('user data: ', usersData);
          const arrayOfObjects = Object.values(usersData).map((obj) => {
            const email = Object.keys(obj)[0];
            const data = obj[email];
            const total = Object.values(data).reduce((accumulator, value) => {
              return accumulator + value;
            }, 0);
            return { email, ...data, total };
          });
          console.log('arrayOfObjects: ', arrayOfObjects);
          this.tableData = arrayOfObjects
          this.loading = false;
        } catch (e) {
          this.loading = false;
          this.tableData = [];
          console.log('error ==> ', e);
        }
      },
      async filterByDates(dates) {
        const { start_date, end_date } = dates;
        const sDate = this.convertDateFormat(start_date);
        const eDate = this.convertDateFormat(end_date);
        this.loading = true;
        console.log('data: ', dates);
        try {
          const usersData = await get(GET_DISTRIBUTOR_ACCOUNT_STATS + `?account_unique_key=${this.account.unique_id}&start_date=${sDate}&end_date=${eDate}`);
          console.log('user data: ', usersData);
          const arrayOfObjects = Object.values(usersData).map((obj) => {
          const email = Object.keys(obj)[0];
          const datas = obj[email];
          const total = Object.values(datas).reduce((accumulator, value) => {
            return accumulator + value;
          }, 0);
            return { email, ...datas, total };
          });
          console.log('arrayOfObjects 1: ', arrayOfObjects);
          this.tableData = arrayOfObjects;
          this.loading = false;
        } catch (e) {
          console.log('error ==> ', e);
        }
      },

      async clearFilter() {
        this.loading = true;
        this.isAgentData = true;
        const id = this.$route.params.id;
        try {
          const usersData = await get(GET_DISTRIBUTOR_ACCOUNT_STATS + `?account_unique_key=${id}`);
          console.log('user data: ', usersData);
          const arrayOfObjects = Object.values(usersData).map((obj) => {
            const email = Object.keys(obj)[0];
            const data = obj[email];
            const total = Object.values(data).reduce((accumulator, value) => {
              return accumulator + value;
            }, 0);
            return { email, ...data, total };
          });
          console.log('arrayOfObjects: ', arrayOfObjects);
          this.tableData = arrayOfObjects
          this.loading = false;
        } catch (e) {
          console.log('error ==> ', e);
        }
      },

      convertDateFormat(newDate) {
        const date = new Date(newDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      },
  
      backToAccounts() {
        this.isAgentData = false;
        this.tableColumns = [
          { key: "name", label: "Name" },
          { key: "email", label: "Email" },
          { key: "username", label: "Username" },
          { key: "phone_number", label: "Phone#" },
        ];
        this.tableData = this.accountsData;  
      },
    },
  };
  </script>
  <style scoped lang="scss">
  .page {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 20px 0px 20px 0px;

    .inner-box {
      height: 100%;
    }
    .is-agent {
      width: 100%;
    }
  
    .foot {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      .errorMsg {
        font-size: 22px;
        color: #ff8a96;
        letter-spacing: 0.5px;
        font-weight: 600;
      }
    }
  
    .unit {
      display: flex;
      align-items: center;
      margin: 30px 0px;
  
      label {
        font-size: 25px;
        font-weight: 500;
        width: 200px;
      }
    }
  
    .select {
      padding: 10px;
      border: none;
      width: 300px;
      border-radius: 3px;
      color: #000000b0;
  
      &::placeholder {
        color: var(--input-placeholder);
        opacity: 0.3;
      }
  
      &:focus {
        outline: none;
        border-color: white;
      }
    }
  }
  
  @media (max-width: 768px) {
    .page {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 100px;
      .inner-box {
        height: 100%;
      }
    }
    .unit {
      label {
        font-size: 15px !important;
      }
    }
  }
  </style>  